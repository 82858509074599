<template>
  <div class="container d-flex flex-column align-items-center justify-content-center" style="height: 100vh;">
    <img src="../assets/images/logo.svg" alt="" class="img-fluid d-lg-none">
    <img src="../assets/images/logo.svg" alt="" class="img-fluid d-none d-lg-block" height="500" width="500">

    <button type="button" @click.prevent="loginBtn" class="line-btn login my-5 py-3 px-4" v-if="!invitationCodeChecked">使用LINE登入</button>

    <div class="my-5" v-if="invitationCodeChecked">
      <LoadingPIc></LoadingPIc>
    </div>



  </div>
</template>

<style scoped>
/* LINE 按鈕開始 */
.line-btn{
  background: rgba(6, 199, 85, 1);
  border: 0;
  color: #ffff;
  border-radius: 7px;
  padding: 5px 10px;
}

.line-btn:hover{
  background: rgba(6, 199, 85, .9);
}

.line-btn:active{
  background: rgba(6, 199, 85, .7)
}
/* LINE 按鈕結束 */
</style>


<script>
import { redirect_uri, scope, myApiVerifyIvitationCode } from '@/stores/Utility.js'
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingPIc from '@/components/LoadingPIc.vue';

export default {
  name: 'Login',
  components: {
    LoadingPIc,
  },
  data() {
    return {
      state: '',
      apiLink: '',
      client_id: '',
      invitationCodeChecked: false,
    };
  },
  methods: {
    loginBtn() {

      Swal.fire ({
        title: '請輸入AQ通關密碼',
        input: 'text',
        text: '密碼從AQ LINE群領取',
        inputPlaceholder: '請輸入通關密碼',
        icon: 'warning',
        allowOutsideClick: false,
        confirmButtonColor: '#06C755',
        confirmButtonText: '確認',
        inputValidator: (value) => {
        if (!value) {
          return "還是要先輸入驗證碼吧~";
        }
      }
      })
      .then( (res) => {
        if (res.isConfirmed){
          const invitationCode = res.value;
          Swal.fire({
            title: '驗證中...',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading()
            }
          })
          axios.post (myApiVerifyIvitationCode,{
            invitationCode: invitationCode,
          })
          .then(res =>{
            //如果驗證碼正確
            if(res.data.success) {
              this.invitationCodeChecked = true;
              this.client_id = res.data.client_id;
              localStorage.setItem('state', this.state);

              //定義API網址
              this.apiLink = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${this.client_id}&redirect_uri=${redirect_uri}&state=${this.state}&scope=${scope}`;

              Swal.fire({
                title: '驗證碼正確！',
                text: '跳轉至LINE登入中',
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1000,
              })
              .then( () => {
                this.lineLogin();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: '通關密碼不對喔！',
                text: '請由AQ群組獲得更新的密碼',
              })
            }
          })
          .catch(() => {
            Swal.fire({
              title: '伺服器錯誤',
              text: '稍後試試唄',
              icon: 'error',
              confirmButtonText: '好唄'
            })
            .then(res => {
              if(res.isConfirmed) {
                this.$router.push({path: '/'});
              }
            })
          })
        }
      })


    },
    lineLogin() {
      window.location.href = this.apiLink;
    },
  },
  mounted() {
    //定義驗證用state
    this.state = Date.now();
  },
}
</script>