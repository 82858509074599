//API網址(index.js)
export const baseUrl = process.env.VUE_APP_BASEURL || 'https://superdrunkaqbackend.zeabur.app/';  //'http://localhost:5000/'是本機端的API網址  //https://aq-backend.zeabur.app/,   https://superdrunkaqbackend.zeabur.app/新的
//傳送code至後端的API網址，aq-dev-backen.zeabur.app
export const myApiUrlCodeForToken = `${baseUrl}api/codefortoken`;
//驗證Token是否有效的API網址
export const myApiUrlVerifyToken = `${baseUrl}api/verifytoken`;
//取得我的訂單的API網址
export const myApiUrlGetOrder = `${baseUrl}api/order/getmyorder`;
//送出訂單的API網址
export const myApiUrlSubmitOrder = `${baseUrl}api/order/submit`;
//刪除我的訂單的API網址
export const myApiUrlDeleteOrder = `${baseUrl}api/order/deletemyorder`;
//取得所有訂單的API網址
export const myApiUrlGetAllOrder = `${baseUrl}api/order/getallorder`;
//驗證邀請碼的API
export const myApiVerifyIvitationCode = `${baseUrl}api/login/invitation`;

export const verifyMyToken = `${baseUrl}api/verifyMyToken`;

// API網址(login.js)
// export const client_id = '2002293653';  //'2002145321'是正事環境的client_id  //'2002293653'是測試環境的client_id
export const redirect_uri = process.env.VUE_APP_REDIRECT_URL || 'https://superdrunkaq.com';
export const scope = 'profile%20openid%20email';