<template>
<div class="container text-center">
  <div>
    <p class="mb-0" style="font-size: 7rem; font-weight: bold;">404</p>
    <p>你迷路了嗎? 找不到頁面?</p>
    <button type="button" class="btn btn-secondary">
      <router-link to="/" class="text-white text-decoration-none">回家吧!</router-link>
    </button>
  </div>
</div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>