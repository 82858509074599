<template>

  <Navbar></Navbar>
  <div class="container-fluid mt-5 py-3 border border-1 rounded-4 shadow-sm" v-if="!removeHeaderAndFooter" style="width: 300px;">
        <div class="text-center mb-2">距離 AQ Tour 2024 還剩</div>
        <Countdown
        :deadline="deadline"
        :labels="{ days: '天', hours: '時', minutes: '分', seconds: '秒', }"
        :mainColor="mainColor"
        :secondFlipColor="secondFlipColor"
        countdownSize="1.5rem"
        labelSize="1rem"
        >
      </Countdown>
  </div>

  <div class="mb-5">
    <router-view></router-view>
  </div>
  <Footer></Footer>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import {Countdown} from 'vue3-flip-countdown'
import { useAuthStore } from './stores/Auth';

export default {
  components: {
    Navbar,
    Footer,
    Countdown,
  },
  data() {
    return {
      deadline: '2024-01-11 00:00:00',
      mainColor: '#ffffff',
      secondFlipColor: '#ffffff'

    };
  },
  created() {
    const authStore = useAuthStore();
    const access_token = localStorage.getItem('access_token');

    if(!authStore.isLoggedIn) {
      if (access_token) {
        //1.驗證accesstoken
        //  1.若失敗，就把sesstion清空，回到登入畫面
        //2.把isLoggedIn 改成true
      } else {
        //回到登入頁面
      }
    }

  },
  computed: {
    removeHeaderAndFooter() {
      return this.$route.meta.removeHeaderAndFooter;
    },
  },
};
</script>


<style lang="scss">
@import "./assets/all.scss";
#app {
  font-family: 'Noto Sans TC', sans-serif;
}

//讓footer跟內容底部有距離
body{
  margin-bottom: 100px;
  margin-top: 120px;
}
</style>
