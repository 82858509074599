<template>
  <div class="my-4">
    <p class="h3 mb-0 text-center">Tour 2024 全團團服統計表</p>
  </div>

  <!-- 以下是表格 -->
  <div class="container table-responsive text-center my-5" v-if="!noOrderData">
    <table class="table table-striped align-middle table-bordered">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">100</th>
          <th scope="col">110</th>
          <th scope="col">120</th>
          <th scope="col">130</th>
          <th scope="col">140</th>
          <th scope="col">150</th>
          <th scope="col">WM</th>
          <th scope="col">S</th>
          <th scope="col">M</th>
          <th scope="col">L</th>
          <th scope="col">XL</th>
          <th scope="col">2XL</th>
          <th scope="col" class="text-nowrap">總計</th>
          <th scope="col" class="text-nowrap">總金額</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr v-for="item in finalData" :key="item.user_id">
          <td class="text-center">
            <div style="width: 20px; height: 20px; margin: auto;">  
              <img :src="item.user_pic" class="rounded-circle img-fluid" alt="">
            </div>
          </td>
          <template  v-for="size in item.orders">
            <td v-if="size === 0">0</td>
            <td v-else>{{ size }}</td>
          </template>
          <td>{{ item.personTotalAmount }}</td>
          <td class="text-nowrap">$ {{ item.personTotalAmount * fixedPrice }}</td>
        </tr>
      </tbody>
      <tfoot class="table-foot">
        <tr>
          <td class="text-nowrap">總計</td>
          <template v-for="(item, index) in totalAmount" :key="index">
            <td>{{ item }}</td>
          </template>
          <td> {{ allAmount }}</td>
          <td class="text-nowrap">$ {{ allAmount * fixedPrice }}</td>
        </tr>
      </tfoot>
    </table>
  </div>

  <div class="container" v-if="noOrderData">
    <div class="text-center h4 my-5 text-danger">
      都還沒有人登記喔QQ
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { useAuthStore } from '@/stores/Auth';
import { useMessageStore } from '@/stores/Message';
import { usePriceStore } from '@/stores/price';
import { myApiUrlGetAllOrder } from '@/stores/Utility';
import { Tab } from 'bootstrap';

export default {
  name: 'Orders',
  data() {
    return {
      fixedPrice: Number(0),
      noOrderData: true,
      orders: [],
      finalData: [],
      size: ['100','110','120','130','140','150','WM','S','M','L','XL','2XL'],
      totalAmount: [0,0,0,0,0,0,0,0,0,0,0,0],
      allAmount: 0,
    };
  },
  methods: {
    dataCleaning(realData) {
      let totalUsers = 0;
      let user_id = '';

      realData.forEach((singleOrder, index_outside) => {
        let tempData = {};  //暫存每筆訂單的資料
        let tempSizeArray = [0,0,0,0,0,0,0,0,0,0,0,0];  //暫存每筆訂單的SIZE資料

        //如果是新的USER ID，就新增一個USER
        if (user_id != singleOrder.user_id){
          totalUsers += 1;
          user_id = singleOrder.user_id; //更新user_id，以便下次比較
          let index = 0; //初始化size的index

          //取得USER單筆訂單的size對應的index
          index = this.size.findIndex(element => {
            return element == singleOrder.p_size;
          })

        //把需要的件數存放到暫存的SIZE資料對應的index
        tempSizeArray[index] += singleOrder.amount;

        //把暫存的資料存放到暫存的USER資料
        tempData.user_id = singleOrder.user_id;
        tempData.user_pic = singleOrder.user_pic;
        tempData.orders = tempSizeArray;

        //把暫存的USER資料存放到最後的資料
        this.finalData.push(tempData);

        //如果是同一個USER ID，就不創建新的USER，直接修改SIZE的件數
        }else if (user_id == singleOrder.user_id){
          //同樣設定size的index
          let index = 0;
          //取得USER單筆訂單的size對應的index
          index = this.size.findIndex(element => {
            return element == singleOrder.p_size;
          })

        //把需要的件數存放到暫存的SIZE資料對應的index
          this.finalData[totalUsers-1].orders[index] += singleOrder.amount;
        }

      })//for 結尾

      //計算個人總件數
      this.finalData.forEach(item => {
        let personTotalAmount = 0;
        item.orders.forEach(item =>{
          personTotalAmount += item;
        });
        item.personTotalAmount = personTotalAmount;
      });

      let tempAmount = 0;
      //計算團體總件數跟價格
      this.totalAmount.forEach((totalAmount, indexOut) => {
        
        this.finalData.forEach((item,index) => {
          this.totalAmount[indexOut] += Number(item.orders[indexOut]);
          if(item.orders[indexOut] != 0) {
            tempAmount+= item.orders[indexOut];
            this.allAmount += item.orders[indexOut];
          }
        })
      });
      console.log(this.allAmount);
    }
  },

  created() {
    // 定義定價
    const priceStore = usePriceStore();
    this.fixedPrice = Number(priceStore.fixedPrice);

    const access_token = localStorage.getItem('access_token')
    if(access_token) {
      axios.get(myApiUrlGetAllOrder,{
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
      .then(res => {
        if (res.data.message === 'no order data') {
          this.noOrderData = true;
        } else {
          this.noOrderData = false;
          this.dataCleaning(res.data.allOders);
        }
      })
      .catch(err => {
        console.log(err.response);
      })
      
    }
  }
}
</script>