<template>
  <div class="text-center mt-5 mb-0 py-2 footer h6 text-white fixed-bottom">
    <p class="mb-0">
      Copyright © 2024 AQ Taiwan. All rights reserved.
    </p>
  </div>  
</template>
<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.footer{
  font-family: Kanit, sans-serif;
  background-color: #121a2f;
}
</style>