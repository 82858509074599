import { defineStore } from "pinia";
import Swal from "sweetalert2";
import router from "@/router";

export const useMessageStore = defineStore('message',{
  state: () => ({
    message: '',
    btnText: '',
  }),
  actions: {
    showErrMessage() {
      Swal.fire({
        title: '網站某種東西怪怪的',
        text: this.message|| '你剛剛在這所做的一切，都跟你的人生一樣『失敗了』',
        icon: 'error',
        confirmButtonText: this.btnText || '確定',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
      })
      .then(res => {
        if (res.isConfirmed) {
          localStorage.clear();
          router.push({path: '/'});

        }
      })
    }
  }
})