// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory  } from 'vue-router'
import Home from '../views/Home.vue'
import MyOrder from '../views/MyOrder.vue'
import Orders from '../views/Orders.vue'
import Login from '../views/Login.vue'
import Journey from '@/views/Journey.vue'
import NotFound from '../views/NotFound.vue'
import MyInfo from '../views/MyInfo.vue'

import { useAuthStore } from '@/stores/Auth'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {title: 'AQ Taiwan | 官方網站'},
  },
  {
    path: '/myorder',
    name: 'myorder',
    meta: { 
      requiresAuth: true,
      title: 'AQ Taiwan | 我的訂單'
    },
    component: MyOrder,
  },
  {
    path: '/orders',
    name: 'orders',
    meta: { 
      requiresAuth: true,
      title: 'AQ Taiwan | 訂單統計'
    },
    component: Orders
  },
  {
    path: '/journey',
    name: 'journey',
    meta: { 
      requiresAuth: true,
      title: 'AQ Taiwan | 精選行程'
    },
    component: Journey
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { 
      removeHeaderAndFooter: true,
      title: 'AQ Taiwan | 登入',
    },
  },
  {
    path: '/myinfo',
    name: 'myinfo',
    component: MyInfo,
    meta: {
      requiresAuth: true,
      title: 'AQ Taiwan | 我的資料',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFound,
    meta: { 
      removeHeaderAndFooter: true,
      title: 'AQ Taiwan | 找不到頁面',
    },
  },
];

//創建路由實體
const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

//創建路由守衛
router.beforeEach((to,from,next) =>{
  document.title = to.meta.title || 'AQ Taiwan | 官方網站';

  const access_token = localStorage.getItem('access_token');
  const authStore = useAuthStore();

  //要建立驗證機制，尚未建立
  if ((to.meta.requiresAuth && !access_token)) {
    authStore.verify(access_token);
  } else {
    next();
  }

  //進到每個頁面都要確認authstore是否有登入
  if (access_token) {
    authStore.checkToken(access_token);
  } else {
    access_token.isLoggedIn =false;
  }
});

export default router
