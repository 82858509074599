<template>
<div class="mt-5">
  <swiper
    :spaceBetween="30"
    :centeredSlides="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
    :navigation="false"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide>
      <img src="@/assets/images/Carousel/1.jpg" alt="">
    </swiper-slide>
    <swiper-slide><img src="@/assets/images/Carousel/2.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/images/Carousel/3.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/images/Carousel/4.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/images/Carousel/5.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="@/assets/images/Carousel/6.jpg" alt=""></swiper-slide>

  </swiper>
</div>

  <!-- <div class="container-fluid my-5" >
      <div class="card">
    <div class="card-header text-center">
      AQ Tour 2017 
    </div>
    <div class="card-body">
      <div class="container d-flex justify-content-center align-items-center py-2 border-danger border-1 rounded-4 shadow ">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/uw34ImomT7E?si=s_hKblaIBbVICv39" title="YouTube video player" frameborder="0" showinfo="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    </div>
  </div>
  </div> -->



</template>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%; */
  height: 500px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
}

</style>

<script>
import { Swiper, SwiperSlide} from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { baseUrl, redirect_uri } from '@/stores/Utility';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



import { useAuthStore } from '@/stores/Auth.js'
import Swal from 'sweetalert2';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  name: 'Home',
  data() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  created() {
    //如果網址列有code跟state，代表準備登入
    const urlParams = new URLSearchParams(window.location.search);
    const code =  urlParams.get('code');
    const urlState = urlParams.get('state');
    const myState = localStorage.getItem('state');

    // 先驗證state跟sesstion存的是否相同，若成立則呼叫登入action
    if ((myState === urlState) && code) {
      localStorage.setItem('code', code);
      const authStore = useAuthStore();

      //執行登入
      authStore.login();

      Swal.fire({
        title: '登入成功!',
        icon: 'success',
        text: '畫面跳轉至首頁...',
        showCancelButton: false,
        showConfirmButton: false,
        timer: '1000',
      })
      .then (() => {
              //清除line login callback 的網址參數
              const urlWithoutParams = window.location.pathname;
              window.history.replaceState({}, document.title, urlWithoutParams);
      })

    }
  },
}
</script>