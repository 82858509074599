<template>
  <div class="d-flex justify-content-center mt-5 ">
    <div class="card p-2 shadow rounded-5" style="width: 18rem;" >
      <div class="rounded-circle overflow-hidden avatar">
				<img :src="myLinePic" class="card-img-top" alt="">
			</div>
      <div class="row justify-content-center">
        <div class="col-5">
          <button type="button" class="btn btn-primary mt-2 text-nowrap" @click.prevent="replaceAvatar">更換照片</button>
        </div>
      </div>
      <div class="card-title mt-3">
        <div class="input-group mb-2">
          <span class="input-group-text">LINE名稱</span>
          <input type="text" class="form-control" :placeholder="myLineName" disabled>
        </div>
        <div class="input-group mb-0">
          <span class="input-group-text">暱稱</span>
          <input type="text" class="form-control" :placeholder="myAQName || '輸入AQ暱稱'">
            <p class="text-danger mb-0" style="font-size: 0.7rem;">*未輸入暱稱則使用LINE名稱作為AQ官網顯示的暱稱</p>
        </div>
      </div>
      <div class="card-body">
        狀態:
        <div class="form-floating">
          <textarea class="form-control" id="floatingTextarea" style="height: 100px"></textarea>
        </div>
      </div>
      <div class="card-footer text-center">
        <button type="button" class="btn btn-secondary mt-2">儲存</button>
      </div>

      
    </div>

  </div>
</template>

<style scoped>
.avatar {
	border: 3px solid white;
	box-shadow: 2px 5px 2px 1px rgba(0, 0, 0, 0.2);
}

</style>

<script>
import Swal from 'sweetalert2';

export default {
  name: 'MyInfo',
  data() {
    return {
      myLineName: '',
      myPic: '',
      myAQName: '',
    };
  },
  created() {
    this.myLineName = localStorage.getItem('user_name');
    this.myLinePic = localStorage.getItem('user_pic');
  },
  mounted() {
    Swal.fire({
      title: '本頁面建置中',
      text: '所有功能皆無效',
      icon: 'warning',
      confirmButtonText: '我知道了',
      allowOutsideClick: false,
      
    })
  },
  methods: {
    async replaceAvatar() {
      const { value: file } = await Swal.fire({
      title: "選擇照片",
      input: "file",
      inputAttributes: {
        "accept": "image/*",
        "aria-label": "Upload your profile picture"
      }
    });
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        Swal.fire({
          title: "你上傳的圖片",
          imageUrl: e.target.result,
          imageAlt: "The uploaded picture",
          confirmButtonText: '無法儲存'
        });
      };
      reader.readAsDataURL(file);
}
    }
  }
}
</script>