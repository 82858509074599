import axios from "axios";
import { defineStore } from "pinia"
import Swal from 'sweetalert2'
import { myApiUrlCodeForToken, verifyMyToken } from '@/stores/Utility.js'
import { useMessageStore } from "./Message";
import router from '@/router';


export const useAuthStore = defineStore('auth',{
  state: () => ({
    isLoggedIn: false,
  }),
  actions: {
    //登入的action
    async login() {
      try{
        //抓line callback 網址參數
        const code = localStorage.getItem('code');
        const response = await axios.post(myApiUrlCodeForToken, {code: code});

        //如果成功就把isLoggedIn 設為true
        this.isLoggedIn= true

        //在session中儲存access_token,pic,sub,name
        const access_token = response.data.token;
        const user_pic = response.data.user.userPic;
        const user_id = response.data.user.userId;
        const user_name = response.data.user.userName;

        if (access_token && user_pic && user_name && user_id) {
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('user_pic', user_pic);
          localStorage.setItem('user_id', user_id);
          localStorage.setItem('user_name', user_name);
        }

        //移除session的code,state
        localStorage.removeItem('code');
        localStorage.removeItem('state');

      } catch(err) {
        //如果後台出錯，顯示登入錯誤
        Swal.fire({
          title: '登入逾時',
          text: '請重新登入',
          icon: 'error',
          allowOutsideClick: false,
          confirmButtonText: '確定',
        });
        this.isLoggedIn = false;
      };
    },

    //驗證是否有登入
    async verify(access_token) {
      // const access_token = localStorage.getItem('access_token');
      if(access_token == null) {
        Swal.fire({
          title: '請先登入',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: '確定'
        })
        .then (res => {
          if(res.isConfirmed) {
            localStorage.clear();
            router.push({path: '/login'});
          }
        })
      } else {

        try {
          const response = await axios.post(verifyMyToken, null, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          })
          if(!response.data.success) {
            Swal.fire({
              title: '登入逾時',
              text: '請重新登入',
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonText: '重新登入'
            })
            .then (res => {
              if(res.isConfirmed) {
                localStorage.clear();
                router.push({path: '/login'});
              }
            })
          } 
        } catch (err) {
          const msgStore = useMessageStore();

          if(err.response) {
            msgStore.message = err.response.data.message || '網路錯誤';
            msgStore.btnText = err.response.data.btnText || '稍後再試';
          }
          msgStore.message = '網路錯誤';
          msgStore.btnText = '稍後再試';
          
          msgStore.showErrMessage();
          msgStore.$reset();
        }
      }


    },

    async checkToken(access_token) {
      try {
        const response = await axios.post(verifyMyToken, null, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        })
        if(!response.data.success) {
          Swal.fire({
            title: '登入逾時',
            text: '請重新登入',
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: '重新登入'
          })
          .then (res => {
            if(res.isConfirmed) {
              localStorage.clear();
              router.push({path: '/login'});
            }
          })
        } else {
          this.isLoggedIn = true;
        }
      } catch(err) {
        const msgStore = useMessageStore();

        if(err.response) {
          msgStore.message = err.response.data.message || '網路錯誤';
          msgStore.btnText = err.response.data.btnText || '稍後再試';
        }
        msgStore.message = '網路錯誤';
        msgStore.btnText = '稍後再試';
        
        msgStore.showErrMessage();
        msgStore.$reset();
      }
    },

    //登出功能
    logout() {
      Swal.fire({
        text: '確定要登出嗎？',
        showCancelButton: true,
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonText: '登出',
        cancelButtonText: '取消',
        icon: 'question',
      }).then (res => {
        if (res.isConfirmed) {
          sessionStorage.clear();
          localStorage.clear();

          Swal.fire({
            title: '掰掰!',
            text: '登出成功',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1500,
          })
          .then(() => {
            window.location.reload();
          })
        }
      })

    },

  },
});