<template>
	<div class="d-flex justify-content-center mt-5 ">
		<div class="card p-2 shadow rounded-5" style="width: 18rem;">
			<div class="rounded-circle overflow-hidden avatar">
				<img src="https://profile.line-scdn.net/0hmutt1mVbMlpJDSaidD1NDXVIPDc-IzQSMTx1bztfOGwxO3UKJW19OWoNbmJgaHIKJzt1aGwMZWtl" class="card-img-top" alt="">
			</div>
		<div class="card-body">
			<h5 class="card-title">林郁欣 統籌</h5>
			<p class="card-text">AQ Tour 首次在海外舉辦，本次到日本九州的精選行程，由這位<del>被PChome耽誤的領隊</del>朋朋全權規劃。</p>
			<div class="text-center">
				<a href="https://docs.google.com/document/d/1LO6LHmdWDuNM8YSo-aye241N3uEJA3SVnfwiYAURXuc/edit"
						class="btn btn-secondary" target="_blank">Google文件查看行程</a>
			</div>
		</div>
	</div>
	</div>
</template>

<style scoped>
.avatar {
	border: 3px solid white;
	box-shadow: 2px 5px 2px 1px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css'

export default {
  name: 'Journey',
}
</script>