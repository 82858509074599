<template>
<div class="my-4">
  <p class="h3 mb-0 text-center">Tour 2024 團服統計</p>
</div>
<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
  </symbol>
</svg>

  <div class="container mt-5">
  <div class="alert alert-warning d-flex align-items-center text-center" role="alert">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
    <div>
      全尺寸一律 $630，超過30件才有折扣
    </div>
  </div>
</div>

<div class="container">
  <div class="alert alert-warning d-flex align-items-center text-center" role="alert">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
    <div>
        女款(WM)領口較寬，袖子及衣寬較合身
    </div>
  </div>
</div>
<div class="container mb-5">
  <div class="alert alert-warning d-flex align-items-center text-center" role="alert">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
    <div>
        領口、袖口、下襬為1X1羅紋
    </div>
  </div>
</div>

<div class="container text-center my-3">
  <img src="../assets/images/look.jpg" class="img-fluid" alt="">
  <br>
  <img src="../assets/images/male.jpg" class="img-fluid" alt="">
  <br>
  <img src="../assets/images/female.jpg" class="img-fluid" alt="">
  <br>
  <img src="../assets/images/kid.jpg" class="img-fluid" alt="">
  <br>
  <img src="../assets/images/size-table.png" class="img-fluid" alt="">
  <br>
  <img src="../assets/images/size-pic.png" class="img-fluid" alt="">
</div>


<div class="content py-5">
    <div class="container d-flex align-items-center my-3">
    <div style="width: 50px; height: 50px;">
      <img :src="userPic" class="rounded-circle img-fluid" alt="avatar">
    </div>
    <div class="ms-3">
      <p class="mb-1">Hi {{ userName }}</p>
      <p class="mb-0" v-if="!alreadyHasOrder">選你要的尺寸跟數量</p>
      <p class="mb-0" v-if="alreadyHasOrder">恭喜，成功下單了！</p>
    </div>
  </div>
  <div>
  </div>
  <form class="container my-5">
      <div class="row d-flex align-items-stretch justify-content-center">
        <div class="col-4">
          <div class="form-floating">
            <select class="form-select my-size" 
            aria-label="Default select example" 
            id="floatingSelectGrid"
            v-model="selectSize" :disabled="alreadyHasOrder">
              <option selected value="100">100</option>
              <option value="110">110</option>
              <option value="120">120</option>
              <option value="130">130</option>
              <option value="140">140</option>
              <option value="150">150</option>
              <option value="WM">WM</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="2XL">2XL</option>
            </select>
            <label for="floatingSelectGrid">尺寸</label>
          </div>
          
        </div>
        <div class="col-4 d-flex">
          <select class="form-select my-quantity" v-model="selectQuantity" :disabled="alreadyHasOrder">
            <option selected value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div class="col-4 d-flex justify-content-center">
          <button type="button" class="btn btn-success d-block w-100" @click.prevent="addItem" :disabled="alreadyHasOrder">新增</button>
        </div>
      </div>
    </form>
    <hr class="py-1">

    <div class="container mb-5">
      <div class="h3 text-center my-5" v-if="alreadyHasOrder">
        你的訂單如下：
      </div>
      <div class="h3 text-center mt-5" v-if="!alreadyHasOrder">
        還沒有訂單，廣快下訂
      </div>
      <div class="text-center mb-5 text-danger" v-if="!alreadyHasOrder">
        1.新增你要的尺寸跟數量<br>
        2.點選下方藍色『確認送出』即可
      </div>

      <!-- 購物車圖案 -->
      <div class="text-center empty-cart" v-if="!renderTable && !alreadyHasOrder">
        <img src="../assets/images/cart-plus-solid.svg" width="150" alt="">
      </div>

      <table ref="mytable" class="table table-striped table-before-send text-center" 
      v-if="(tempOrder.length > 0 || renderTable) || alreadyHasOrder" >
        <thead>
          <tr>
            <th>尺寸</th>
            <th>數量</th>
            <th>價格</th>
            <th v-if="!alreadyHasOrder">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tempOrder" :key="item.size" v-if="!alreadyHasOrder">  
            <td>{{ item.size }}</td>
            <td>{{ item.quantity }}</td>
            <td>$ {{ item.price }}</td>
            <td>
              <button type="button" class=" btn btn-outline-danger" @click="delItem(index)" >刪除</button>
            </td>
          </tr>
          <tr v-for="(item, index) in myOrder" :key="item.size" v-if="alreadyHasOrder">  
            <td>{{ item.p_size }}</td>
            <td>{{ item.amount }}</td>
            <td>$ {{ item.amount * fixedPrice }}</td>
            <td v-if="!alreadyHasOrder">
              <button type="button" class=" btn btn-outline-danger" @click="delItem(index)" >刪除</button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="1"></td>
            <td>總計</td>
            <td>$ {{ totalAmount || totalPrice }}</td>
            <td v-if="!alreadyHasOrder"></td>
          </tr>
        </tfoot>
      </table>
    </div>
    
    <div class="container text-center bottom-btn">
      <button type="button" class="send-order btn btn-primary mx-3"
      v-if="tempOrder.length > 0" @click.prevent="submitBtn">確認送出</button>
      <button type="button" class="btn btn-danger btn-reset-order" v-if="alreadyHasOrder" @click.prevent="delMyOrder">刪除重訂</button>
    </div>
    <div class="overlay justify-content-center  align-items-center d-flex h1 ">
      <div class="card">
        <div class="card-body text-danger text-center">
          2024已經結單囉!<br>明年請早(還有的話)
        </div>
      </div>
    </div>
</div>

</template>

<script>

import { useAuthStore } from '@/stores/Auth';
import { usePriceStore } from '@/stores/price';
import Swal from 'sweetalert2';
import moo_looking from '@/assets/images/mooLooking.jpg';
import axios from 'axios';
import { myApiUrlDeleteOrder, myApiUrlGetOrder, myApiUrlSubmitOrder } from '@/stores/Utility';
import { useMessageStore } from '@/stores/Message';

export default {
  name: 'MyOrder',
  data() {
    return {
      selectSize: '100',
      selectQuantity: Number(1),
      userId: '',
      userPic: '',
      userName: '',
      tempOrder: [],
      fixedPrice: Number(0),
      tempPrice: Number(0),
      totalAmount: Number(0),
      myTable: null, //滑動螢幕用
      renderTable: false,
      alreadyHasOrder: false,
      myOrder: [],
    };
  },
  methods: {
    //進入頁面跟新增按扭送出，要改變alreadyHasOrder
    getMyOrder() {
      const access_token = localStorage.getItem('access_token');
      axios.get(myApiUrlGetOrder,{
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
      .then(res => {
        if (res.data.success) {
          this.myOrder = res.data.order;
          if (this.myOrder.length > 0) {
            this.alreadyHasOrder = true;
          }
        }
      })
      .catch(err => {
        console.log(err.response);
      })
    },
    //新增按鈕
    addItem() {
      // 先讓表格顯示
      this.renderTable = true;

      const currentSize = this.selectSize;
      const sameSizeIndex = this.tempOrder.findIndex(item => item.size ===currentSize);
      if( sameSizeIndex !== -1) {
        this.tempOrder[sameSizeIndex].quantity += Number(this.selectQuantity);
        this.tempOrder[sameSizeIndex].price = this.fixedPrice * this.tempOrder[sameSizeIndex].quantity;

        this.selectSize = '100';
        this.selectQuantity = 1;

        this.$refs.mytable.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });

        return
      }
      this.$nextTick(() =>{
        this.$refs.mytable.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      })

      //把選擇的定義到暫存物件
      const mySingleSelect = {
        size: this.selectSize,
        quantity: this.selectQuantity,
        price: this.fixedPrice * this.selectQuantity,
      }
      //推送到暫存陣列
      this.tempOrder.push(mySingleSelect);
      //將選項返回預設值
      this.selectSize = '100';
      this.selectQuantity = Number(1);
    },

    //submit按鈕
    submitBtn() {
      //先驗證
      const authStore = useAuthStore();
      authStore.verify();

      const access_token = localStorage.getItem('access_token');

      Swal.fire({
        title: '好好檢查喔',
        imageUrl: moo_looking,
        imageAlt: "Custom image",
        text: '訂錯的話洗乾淨去房間等我',
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        showCancelButton: true,
        allowOutsideClick: false,
      })
      .then (res =>{
        if(res.isConfirmed) {
          //顯示傳送中
          Swal.fire({
            title: '正在送出...',
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading()
            }
          });

          axios.post(myApiUrlSubmitOrder, {
            order: this.tempOrder,
          }, {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          })
          //如果成功
          .then(res => {
            if (res.data.success) {
              Swal.close();
              Swal.fire({
                icon: 'success',
                title: '成功送出',
                showConfirmButton: false,
                timer: 1500
              })
              .then(() =>{
                this.tempOrder = [];
                this.getMyOrder();
              })
            }
          })
          .catch( () => {
            const msgStore = useMessageStore();
            msgStore.showErrMessage();
          })
        }
      })
    },
    //刪除單一項
    delItem(index) {
      this.tempOrder.splice(index,1);
    },
    delMyOrder() {
      Swal.fire({
        title: '確定要刪除嗎？',
        text: "刪除後要重新下訂喔",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: '確定',
        cancelButtonText: '取消',
      })
      .then(res => {
        if (res.isConfirmed) {
          Swal.fire({
          title: '正在送出...',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading()
          }
        });
        const access_token = localStorage.getItem('access_token');
        axios.get(myApiUrlDeleteOrder, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        })
        .then(() => {
          Swal.fire({
          position: "center",
          icon: "success",
          title: "刪除成功",
          showConfirmButton: false,
          timer: 1500
        })
        .then(() => {
          this.alreadyHasOrder = false;
          this.myOrder = [];
          this.tempOrder = [];
          })
        })
        .catch (err => {
          console.log(err.response);
        })
        }
      })
      
    },
  },

  watch: {
    //重新計算總價
    tempOrder: {
      handler() {
        if(this.tempOrder.length === 0) {
          this.renderTable = false;
        }

        this.totalAmount = Number(0);
        this.tempOrder.forEach(item => {
          this.totalAmount += item.price;
        })
      },
      deep: true
    }
  },
  created() {
    //定義錯誤訊息
    const msgStore = useMessageStore();
      //一進入頁面先驗證JWT token
    try {
      const authStore = useAuthStore();
      authStore.verify();
    } catch {
      msgStore.showErrMessage();
    }


    //先把sesstion的資料取出備用
    this.userId = localStorage.getItem('user-id');
    this.userPic = localStorage.getItem('user_pic');
    this.userName = localStorage.getItem('user_name');

    // 定義定價
    const priceStore = usePriceStore();
    this.fixedPrice = Number(priceStore.fixedPrice);

    this.getMyOrder();
  },
  computed: {
    //已經有訂單的狀況下，計算訂單總金額
    totalPrice() {
      return this.myOrder.reduce((total, item) => total + this.fixedPrice * item.amount, 0);
    }
  },
  mounted() {
    Swal.fire({
      title: '2024 團服已經結單囉！',
      text: '',
      icon: 'warning',
      confirmButtonText: '只能QQ'
    });
  },
}
</script>

<style scoped>
.content{
  position: relative;
}
.overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
</style>