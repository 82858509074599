<template>
  <div class="fixed-top">
    <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom shadow-sm">
  <div class="container-fluid">
    <router-link to="/">
      <img src="../assets/images/logo.svg" width="100" alt="">
    </router-link>
    
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <label class="hamburger">
        <input type="checkbox">
        <svg viewBox="0 0 32 32">
          <path class="line line-top-bottom" d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
          <path class="line" d="M7 16 27 16"></path>
        </svg>
      </label>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-center">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page">首頁</router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Tour 2024
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <router-link to="myorder" class="dropdown-item">
                團服登記 <span class="badge rounded-pill bg-danger">已結單</span>
              </router-link>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <router-link to="orders" class="dropdown-item">團服統計</router-link>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <router-link to="journey" class="dropdown-item">精選行程</router-link>
            </li>
          </ul>
        </li>
      </ul>

      <div class="dropdown" v-if="isLoggedIn">
        <button type="button" class="btn btn-outline-secondary border-0 text-dark" 
                data-bs-toggle="dropdown"
                aria-expanded="false">
          <img :src="avatarUrl" alt="" style="width: 50px;" class="rounded-circle">
          <span class="ms-2">{{ userName }}</span>
          <i class="ms-1 bi bi-caret-down-fill"></i>
          <ul class="dropdown-menu text-center">
            <li>
              <a href="#" class="dropdown-item" @click.prevent="toMyInfo">
                <i class="bi bi-person"></i>
                個人資料
              </a>
            </li>
            <li>
              <a href="#" class="dropdown-item" @click.prevent="logout">
                登出
                <i class="bi bi-box-arrow-right ms-1"></i>
              </a>
            </li>
          </ul>
        </button>
      </div>

      
    </div>
  </div>
</nav>
  </div>
</template>

<style scoped>
.avatar {
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.2);
  border: 2px solid white;
}

/* 漢堡選單開始 */
.hamburger {
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  /* The size of the SVG defines the overall size */
  height: 1.5em;
  /* Define the transition for transforming the SVG */
  -webkit-transition: -webkit-transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: #121a2f;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  /* Define the transition for transforming the Stroke */
  -webkit-transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
              stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
              stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}
/* 漢堡選單結束 */
</style>

<script>
import { useAuthStore } from '@/stores/Auth.js'
import { mapState } from 'pinia';
export default {
  name: 'Navbar',
  data() {
    return {
      avatarUrl: '',
      userName: '',
    };
  },
  methods: {
    logout() {
      const authStore = useAuthStore();
      authStore.logout();
    },
    toMyInfo() {
      this.$router.push({path: '/myinfo'});
    }
  },

  computed: {
    ...mapState(useAuthStore, ['isLoggedIn']),
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue == true) {
        this.avatarUrl = localStorage.getItem('user_pic');
        this.userName = localStorage.getItem('user_name');
      }
    }
  }
};

</script>